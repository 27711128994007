


























































import axios from '@/service'
import Static from './components/Static/index.vue'
import { defineComponent } from '@vue/composition-api'
import LoginDialog from '@/views/components/LoginDialog.vue'
import { getToken } from '@/utils'
export default defineComponent({
  components: { Static, LoginDialog },
  name: 'OverView',
  data() {
    return {
      relationCount: 0,
      propertyCount: 0,
      relations: null,
      properties: null,
      isPropertyDialog: false,
      isRelationDialog: false,
      dialog: false,
    }
  },
  mounted() {
    this.initOverview()
    if (!getToken()) {
      this.dialog = true
    }
  },
  methods: {
    async initOverview() {
      const {
        data: { data: relationData },
      } = await axios.get('/find/default/relationshipTypes', {
        headers: {
          Authorization: `${getToken()}`,
        },
      })
      this.relationCount = relationData.length
      this.relations = relationData
      const {
        data: { data: propertyData },
      } = await axios.get('/find/default/properties', {
        headers: {
          Authorization: `${getToken()}`,
        },
      })
      this.propertyCount = propertyData.length
      this.properties = propertyData
    },
  },
})
