import { Pie } from '@antv/g2plot'

const usePieGraph = (containerName: string, getData: GraphStaticData) => {
  containerName || (containerName = 'pie-container')
  ;(document.getElementById(containerName) as HTMLElement).innerHTML = ''
  const pieDataRaw = getData['获奖情况'][0]
  const pieData = Object.keys(pieDataRaw).map(key => ({
    type: key,
    value: pieDataRaw[key as RewardCategories],
  }))
  const pie = new Pie(containerName, {
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  })
  pie.render()
}
export default usePieGraph
