
























import axios from '@/service'
import useBarGraph from './bargraph'
import usePieGraph from './piegraph'
import useWordCloud from './wordcloud'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import NameList from './NameList.vue'
import { getToken } from '@/utils'
export default defineComponent({
  name: 'Static',
  components: {
    NameList,
  },
  setup() {
    const teacherList = ref([])
    const memebersNumber = ref(0)
    const academyCategories = ref([])
    const selectedModel = ref('')
    const initAcademyCategories = async () => {
      const {
        data: { data: getData },
      } = await axios.get('/statistics/competition/names', {
        headers: {
          Authorization: `${getToken()}`,
        },
      })
      academyCategories.value = getData
      selectedModel.value = getData[1]
    }
    const initAcademyGraph = async (): Promise<void> => {
      const {
        data: { data: getData },
      } = await axios.get(
        `/statistics/competition?name=${encodeURIComponent(
          selectedModel.value,
        )}`,
        {
          headers: {
            Authorization: `${getToken()}`,
          },
        },
      )
      teacherList.value = getData['指导老师']
      memebersNumber.value = getData['参赛人数']
      useBarGraph('bar-container', getData)
      usePieGraph('pie-container', getData)
      useWordCloud('word-container', getData)
    }
    onMounted(async () => {
      await initAcademyCategories()
    })

    watch(selectedModel, async () => {
      selectedModel && (await initAcademyGraph())
    })

    return {
      teacherList,
      memebersNumber,
      academyCategories,
      selectedModel,
    }
  },
})
