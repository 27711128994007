










import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'NameList',
  props: {
    title: String,
    list: Array,
  },
})
