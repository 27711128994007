import axios from '@/service'
import { Bar } from '@antv/g2plot'
const useBarGraph = (containerName: string, getData: GraphStaticData) => {
  containerName || (containerName = 'bar-container')
  ;(document.getElementById(containerName) as HTMLElement).innerHTML = ''
  const barDataRaw = getData['参赛学院'][0]
  const barData = Object.keys(barDataRaw).map(key => ({
    text: key,
    value: barDataRaw[key],
  }))
  const bar = new Bar(containerName, {
    data: barData,
    xField: 'value',
    yField: 'text',
    width: 800,
    seriesField: 'text',
    meta: {
      text: {
        alias: '学院',
      },
      value: {
        alias: '获奖人数',
      },
    },
  })
  bar.render()
}
export default useBarGraph
