




























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LoginDialog',
  props: {
    dialog: Boolean,
  },
  // eslint-disable-next-line no-unused-vars
  setup({ dialog }, { root: { $router: router } }) {
    const handleRouterLogin = () => {
      dialog = false
      router.push('/login')
    }
    return {
      handleRouterLogin,
    }
  },
})
