import { WordCloud } from '@antv/g2plot'
const useWordCloud = (containerName: string, getData: GraphStaticData) => {
  containerName || (containerName = 'word-container')
  ;(document.getElementById(containerName) as HTMLElement).innerHTML = ''
  const wordDataRaw = getData['指导老师']
  const wordData = Object.keys(wordDataRaw).map(key => {
    return {
      text: key,
      value: Object.values(wordDataRaw[key]).reduce((res, val) => res + val, 0),
    }
  })
  const word = new WordCloud(containerName, {
    data: wordData,
    wordField: 'text',
    weightField: 'value',
    color: '#122c6a',
    wordStyle: {
      fontSize: [24, 60],
    },
    interactions: [{ type: 'element-active' }],
    state: {
      active: {
        style: {
          lineWidth: 2,
        },
      },
    },
  })
  word.render()
}

export default useWordCloud
